var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expande-horizontal fonte pt-3 column"},[(_vm.getFiles.length)?_c('v-list',[_vm._l((_vm.getFiles),function(file,index){return [_c('v-list-item',{key:file.nome},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":_vm.$theme.secondary}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-archive")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(file.name)+" ")]),_c('v-list-item-subtitle',[_c('a',{attrs:{"target":"_blank","href":file.link}},[_vm._v(" ACESSE O ARQUIVO ")])])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"red lighten-2","icon":""},on:{"click":function($event){return _vm.createConfirmAction({
                icon: 'mdi-warning',
                color: 'yellow darken-3',
                message: 'Remover este arquivo?',
                action: 'removeFile',
                action_value: index
              })}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)],1),_c('v-divider',{key:index})]})],2):_c('div',{staticClass:"expande-horizontal centraliza",staticStyle:{"min-height":"200px","border-radius":"6px","border":"1px solid #f3f3f3"}},[_c('span',{},[_vm._v(" Não há arquivos ainda ")])]),_c('div',{staticClass:"input-wrapper2",staticStyle:{"display":"flex","min-height":"40px","align-items":"center"}},[_vm._m(0),_c('input',{staticStyle:{"cursor":"pointer"},attrs:{"id":"input-file2","type":"file","name":"importxml"},on:{"change":_vm.upload}}),_c('span',{attrs:{"id":"file-name2"}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"input-file2"}},[_c('span',{staticClass:"fonte white--text mr-1"},[_vm._v("Adicionar um Arquivo")])])
}]

export { render, staticRenderFns }