<template>
  <v-dialog transition="slide-x-transition" fullscreen v-model="opened">
    <div
      class="expande-horizontal pa-3 vitrify"
      :class="{ 'pa-3': $vuetify.breakpoint.smAndDown }"
    >
      <div class="expande-horizontal centraliza">
        <v-flex xs12 md5>
          <v-card class="fonte">
            <div class="expande-horizontal wrap">
              <div class="windows-style expande-horizontal">
                <v-flex xs12>
                  <v-list color="transparent" dense class="ma-0 pa-0">
                    <v-list-item class="px-0">
                      <v-list-item-content>
                        <v-list-item-title>
                          Seja bem vindo(a)
                        </v-list-item-title>
                        <span class="fonte fonteMini grey--text">
                          Aprenda mais sobre o sistema com nossos guias abaixo.
                        </span>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar
                          @click="opened = false"
                          style="cursor: pointer;"
                          size="33"
                          class="mr-2"
                          color="#333"
                        >
                          <v-icon :color="$theme.primary">mdi-close</v-icon>
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </div>
              <div
                style="min-height: 20vh; max-height: 70vh; overflow: auto; justify-content: flex-start; align-items: flex-start; border-bottom-right-radius: 6px;border-bottom-left-radius: 6px;"
                class="expande-horizontal wrap windows-style-content"
              >
                <v-expansion-panels flat>
                  <v-expansion-panel
                    dense
                    v-for="section in playlist"
                    :icon="section.icon"
                    :key="section.name"
                    v-show="section.show"
                    class="expande-horizontal wrap"
                  >
                    <v-expansion-panel-header>
                      <v-list dense color="transparent">
                        <v-list-item class="px-2 mx-0">
                          <v-avatar class="mr-2" size="33" color="#333">
                            <v-icon :color="$theme.primary">{{
                              section.icon
                            }}</v-icon>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              section.name
                            }}</v-list-item-title>
                            <span class="grey--text fonteMini">{{
                              section.description
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-for="lesson in section.lessons"
                      class="expande-horizontal wrap"
                      :key="lesson.video"
                    >
                      <v-list dense color="transparent">
                        <v-list-item
                          @click="openVideo(section, lesson)"
                          class="px-2 mx-0 item-list"
                        >
                          <v-avatar
                            size="21"
                            :class="{ 'ml-1': $vuetify.breakpoint.smAndDown }"
                            class="mr-2"
                            @click="lessonPlaying = lesson"
                          >
                            <v-icon color="#333">mdi-play-circle</v-icon>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              lesson.title
                            }}</v-list-item-title>
                            <span class="grey--text fonteMini">{{
                              lesson.subtitle
                            }}</span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-flex
                        v-if="lesson.showVideo"
                        style="border-radius: 6px;"
                        class="px-3"
                        xs12
                      >
                        <div
                          style="border-radius: 6px; max-width: 100%; min-width: 100%;"
                        >
                          <div
                            style="max-width: 100%; min-width: 100%;"
                            v-html="lesson.video"
                          ></div>
                        </div>
                      </v-flex>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </v-card>
        </v-flex>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      opened: false,
      playlist: [
        // {
        //   name: 'dashboard',
        //   icon: 'mdi-view-dashboard-outline',
        //   // show: () => this.get_permission_user["dashboard"],
        //   show: true,
        //   lessons: []
        // },
        {
          name: "Caixa",
          description:
            "Veja abaixo como manusear o caixa, clique em qualquer aula para assistir.",
          route: "/atendimento",
          icon: "mdi-cash-register",
          // show: () => this.get_permission_user["ponto-de-venda"],
          show: true,
          lessons: [
            {
              title: "Guia 1: Abrir um novo caixa",
              showVideo: false,
              subtitle:
                "Um caixa é aberto para cada usuário que irá gerar orçamentos e vendas, nele é registrado qualquer movimentação financeira dentro do sistema, veja como abrir um novo caixa no vídeo a seguir.",
              video:
                '<iframe src="https://www.youtube.com/embed/DZXSSVwOX7M?si=Wol9G6JESdOLGPlm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
            },
            {
              title: "Guia 2: Fechar um caixa",
              subtitle:
                "Um caixa é aberto para cada usuário que irá gerar orçamentos e vendas, nele é registrado qualquer movimentação financeira dentro do sistema, veja como abrir um novo caixa no vídeo a seguir.",
              showVideo: false,
              video:
                '<iframe src="https://www.youtube.com/embed/DZXSSVwOX7M?si=Wol9G6JESdOLGPlm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
            }
          ]
        },
        {
          name: "Gestor de vendas",
          description:
            "É no gestor que você vai dar andamento para suas vendas, você pode criar e mover suas vendas entre colunas.",
          route: "/atendimento",
          icon: "mdi-arrow-decision",
          // show: () => this.get_permission_user["ponto-de-venda"],
          show: true,
          lessons: [
            {
              title: "Guia 1: Abrir um novo caixa",
              showVideo: false,
              subtitle:
                "Um caixa é aberto para cada usuário que irá gerar orçamentos e vendas, nele é registrado qualquer movimentação financeira dentro do sistema, veja como abrir um novo caixa no vídeo a seguir.",
              video:
                '<iframe src="https://www.youtube.com/embed/DZXSSVwOX7M?si=Wol9G6JESdOLGPlm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
            },
            {
              title: "Guia 2: Fechar um caixa",
              subtitle:
                "Um caixa é aberto para cada usuário que irá gerar orçamentos e vendas, nele é registrado qualquer movimentação financeira dentro do sistema, veja como abrir um novo caixa no vídeo a seguir.",
              showVideo: false,
              video:
                '<iframe src="https://www.youtube.com/embed/DZXSSVwOX7M?si=Wol9G6JESdOLGPlm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
            }
          ]
        }
      ]
    };
  },
  methods: {
    open(type) {
      this.opened = true;
    },
    openVideo(section, lesson) {
      this.playlist = this.playlist.map(item => {
        if (item.name === section.name) {
          item.lessons = item.lessons.map(lessonItem => {
            if (lessonItem.title === lesson.title) {
              lessonItem.showVideo = true;
            } else {
              lessonItem.showVideo = false;
            }
            return lessonItem;
          });
        }
        return item;
      });
    }
  },
  computed: {
    ...mapGetters(["get_permission_user"])
  }
};
</script>
<style>
iframe {
  min-width: 100%;
  max-width: 100%;
  position: relative;
  min-height: 270px;
  border-radius: 6px !important;
}
</style>
