export const mutations = {
  setLoggedUser(state, payload) {
    state.loggedUser = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setEmpresaAtiva(state, payload) {
    state.empresaAtiva = payload;
  },
  setModalForgotPassword(state, payload) {
    state.modalForgotPassword = payload;
  },
  setSponsor(state, payload) {
    state.sponsor = payload;
  }
};

export default {};
