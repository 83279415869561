export const state = {
  loggedUser: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
  empresaAtiva: localStorage.getItem("ampresaAtiva")
    ? JSON.parse(localStorage.getItem("empresaAtiva"))
    : null,
  modalForgotPassword: false,
  sponsor: {
    valid: false,
    name: ""
  }
};

export default {};
