var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-slide-group',{attrs:{"show-arrows":""}},[_c('v-dialog',{staticStyle:{"height":"100vh","background":"rgba(215, 215, 215, 0.1)","backdrop-filter":"blur(20px)"},attrs:{"fullscreen":""},model:{value:(_vm.viewImgDialog),callback:function ($$v) {_vm.viewImgDialog=$$v},expression:"viewImgDialog"}},[_c('div',{staticClass:"expande-horizontal centraliza",staticStyle:{"height":"100vh","background":"rgba(215, 215, 215, 0.1)","backdrop-filter":"blur(20px)"}},[_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('div',{staticClass:"expande-horizontal centraliza pa-6",staticStyle:{"background":"#e2e2e2","flex-direction":"column","border-radius":"6px"}},[_c('div',{staticClass:"expande-horizontal"},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":_vm.$theme.primary,"dark":""},on:{"click":function($event){return _vm.setModalViewImg('', false)}}},[_c('v-icon',[_vm._v("mdi-close")]),_c('span',{staticClass:"fonte"},[_vm._v("fechar")])],1),_c('v-spacer')],1),_c('img',{style:(`max-height: 70vh; border-radius: 6px;`),attrs:{"src":_vm.link}})])])],1)]),_vm._l((_vm.getLinks),function(link,i){return _c('v-slide-item',{key:link},[_c('v-card',{staticClass:"pa-2 pl-0 ml-0 pl-2 ma-3",attrs:{"color":"#333","width":"140","height":"180"}},[_c('v-img',{staticStyle:{"border-radius":"6px","height":"140px","width":"400px","cursor":"pointer"},attrs:{"src":link},on:{"click":function($event){return _vm.setModalViewImg(link, true)}}}),_c('v-btn',{staticClass:"fonte",attrs:{"text":"","block":""},on:{"click":function($event){_vm.createConfirmAction({
            opened: true,
            message: 'Remover Imagem?',
            action: () => _vm.removeLink(link),
            isScoped: true,
            action_value: link
          })}}},[_c('span',{staticClass:"fonteMini text-capitalize grey--text"},[_vm._v("remover")]),_c('v-icon',{attrs:{"color":"grey","size":"12"}},[_vm._v("mdi-image-remove")])],1)],1)],1)}),_c('v-slide-item',[(_vm.showOnCondition)?_c('v-card',{staticClass:"pa-2 pl-0 ml-0 pl-2 ma-3",attrs:{"color":"#333","width":"140","height":"180"}},[_c('div',{staticClass:"input-wrapper",staticStyle:{"display":"flex","height":"140px","align-items":"center"}},[_c('label',{attrs:{"for":"input-file"}},[_c('div',{staticClass:"expande-horizontal centraliza",staticStyle:{"cursor":"pointer","width":"67px","height":"80px"}},[(_vm.initialized)?_c('v-icon',{attrs:{"size":"30","color":"#333"}},[_vm._v("mdi-cloud-upload-outline")]):_c('v-icon',{attrs:{"size":"50","color":"grey"}},[_vm._v("mdi-image-plus")]),(_vm.initialized)?_c('v-progress-circular',{staticStyle:{"margin-left":"-40px"},attrs:{"value":_vm.loading,"size":"50","indeterminate":_vm.initialized && !_vm.loading,"color":_vm.$theme.primary}}):_vm._e()],1)]),_c('input',{ref:"input",staticStyle:{"cursor":"pointer"},attrs:{"id":"input-file","type":"file","accept":"image/*","name":"importxml"},on:{"change":_vm.upload}}),_c('span',{attrs:{"id":"file-name"}})])]):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }