export const getters = {
  getModalSendArchive: state => state.modalSendArchive,
  getLinks: state => state.links,
  getLinksById: state => state.linksById,
  getFiles: state => state.files,
  getFile: state => state.file,
  getCurrentLinkInfo: state => state.currentLinkInfo,
  getPercentageSentArchive: state => state.percentageSentArchive,
  getModalGlobalMessage: state => state.modalGlobalMessage,
  getModalConfirmAction: state => state.modalConfirmAction,
  getModalLoading: state => state.modalLoading,
  getPersonalizedLoading: state => state.personalizedLoading
};

export default {};
